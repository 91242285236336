<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="home-page">
    <!-- Hero -->
    <section id="hero">
      <div :style="{ background: hero.bg }">
        <div class="container">
          <div class="row">
            <div class="text col-24 col-md-12">
              <h2 v-html="hero.header" />
              <div class="body">
                {{ hero.body }}
              </div>
            </div>
            <div
              class="col-24 col-md-12"
              :class="{ img: !!hero.img, video: !!hero.videoId }"
            >
              <img
                v-if="hero.img"
                :src="hero.img"
              >
              <div
                v-else-if="hero.videoId"
                class="video-container"
              >
                <iframe
                  :src="`https://www.youtube.com/embed/${hero.videoId}?rel=0`"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                />
              </div>
            </div>
          </div>
        </div>
        <svg
          class="waves"
          viewBox="0 0 1695 75"
        >
          <g
            fill="#000"
            fill-rule="evenodd"
          >
            <path
              d="M1695 37c-172.267-24.667-288.075-37-347.424-37-235.393 0-362.51 37-500.076 37C729.142 37 554.567 0 363.792 0 287.247 0 165.982 12.333 0 37v38l1695-1V37z"
              opacity=".61"
            />
            <path d="M0 12c170.016 20.667 304.159 31 402.428 31 147.405 0 205.312-31 441.418-31s298.731 31 492.4 31C1465.36 43 1584.945 32.667 1695 12v62H0V12z" />
          </g>
        </svg>
      </div>
    </section>

    <!-- Callouts -->
    <section id="callouts">
      <div class="container">
        <div class="callout-container row">
          <article
            v-for="(callout, i) of callouts"
            :key="i"
            class="callout col col-md-8"
          >
            <div class="icon">
              <fa-icon :icon="callout.icon" />
            </div>
            <div class="header">
              {{ callout.header }}
            </div>
            <div class="body">
              {{ callout.body }}
            </div>
          </article>
        </div>
        <a
          class="btn blue"
          @click="signup"
        >
          Sign Up For Free
        </a>
      </div>
    </section>

    <!-- Map -->
    <section id="map">
      <div class="container">
        <div class="sponsors">
          <a
            v-for="(sponsor, i) of sponsors"
            :key="i"
            class="sponsor"
            :href="sponsor.href"
            target="_blank"
          >
            <img :src="sponsor.img">
          </a>
        </div>

        <h2>Ready to Fly? Find your Plane, Partner or Professional</h2>

        <p class="sub-h2">
          CLICK ON THE MAP TO BEGIN
        </p>

        <div class="map-container">
          <client-only>
            <gmap-map
              ref="map"
              class="map"
              :options="{ disableDefaultUI: true, gestureHandling: 'none', zoomControl: false }"
              :center="mapCenter"
              :zoom="mapZoom"
            >
              <!-- <gmap-cluster
              ref="cluster"
              :styles="[{
                url: clusterIconUrl,
                width: 64,
                height: 64,
                textSize: 14,
                fontWeight: 400,
                fontFamily: 'Roboto, Arial, sans-serif'
              }]"
              :calculator="(markers, numStyles) => {
                const { users, instructors, shares } = markers.reduce((count, marker) => {
                  const [users, instructors, shares] = marker.title.split(', ').map(count => parseInt(count.replace(/[^\d]/g, '')));
                  count.users += users;
                  count.instructors += instructors;
                  count.shares += shares;
                  return count;
                }, { users: 0, instructors: 0, shares: 0 });

                return {
                  index: 1,
                  text: users + instructors + shares,
                  title: `${markers.length} airports, ${users} pilot${ users !== 1 ? 's' : ''}, ${instructors} professional${ instructors !== 1 ? 's' : ''}, ${shares} plane${ shares !== 1 ? 's' : ''}`
                };
              }"
              @click="onClusterClick"
            > -->
              <gmap-marker
                v-for="count in serachAirportCounts"
                :key="count.airport"
                :position="{
                  lat: count.lat,
                  lng: count.long
                }"
                :icon="{
                  url: createSearchIcon(count),
                  scaledSize: {
                    width: 64,
                    height: 64
                  }
                }"
                :title="`${count.user} pilot${ count.user !== 1 ? 's' : ''}, ${count.instructor} professional${ count.instructor !== 1 ? 's' : ''}, ${count.share} plane${ count.share !== 1 ? 's' : ''}`"
                @click="onMapMarkerClick(count)"
              />
            <!-- </gmap-cluster> -->
            </gmap-map>
          </client-only>

          <div class="legend">
            <div class="header">
              Legend
            </div>
            <div class="content">
              <div class="type plane">
                <img src="@/assets/icons/plane.png">
                Plane
              </div>
              <div class="type pilot">
                <img src="@/assets/icons/pilot.png">
                Pilot
              </div>
              <div class="type professional">
                <img src="@/assets/icons/professional.png">
                Professional
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="totalCounts"
          class="stats"
        >
          <div>
            <span class="num">{{ totalCounts.shares.toLocaleString('en-us') }}</span>
            Planes Available for Partnerships.
          </div>
          <div>
            <span class="num">{{ (totalCounts.users * 2).toLocaleString('en-us') }}</span>
            Pilots looking to Partner.
          </div>
        </div>
      </div>
    </section>

    <!-- Recent Shares -->
    <section id="recent-shares">
      <div class="container">
        <div class="header">
          <h2>
            Recently Listed Planes
          </h2>
          <div class="subheading">
            The most recently listed planes near {{ geoAddress }}
          </div>
        </div>
        <div class="row shares">
          <div
            v-for="share in recentShares"
            :key="share.id"
            class="col col-sm-12 col-md-6"
          >
            <sma-share-card
              class="share"
              :share="share"
            />
          </div>
        </div>
        <a
          class="btn blue"
          @click="onShowMoreRecentBtnClick"
        >
          See More
        </a>
      </div>
    </section>

    <!-- Announcements-->
    <!-- <section id="announcements">
      <div class="container">
        <h2>
          Announcements
        </h2>

        <div class="row">
          <div class="col col-sm-3">
            <img
              src="@/assets/coflyt_logo.png"
              class="shrink-announce-image"
            >
          </div>
          <div class="col col-sm-9">
            <p>Coflyt® is intelligent aircraft software that combines inspections and maintenance tracking, scheduling, and billing into a simple app, providing peace of mind for aircraft owners. Know exactly when aircraft maintenance is needed, streamline communication, and ensure compliance – all from the palm of your hand.</p>

            <router-link
              class="btn blue"
              :to=" { name: 'coflyt' }"
            >
              Learn More
            </router-link>
          </div>
          <div class="col col-sm-3">
            <img
              src="@/assets/cirrus_partner_logo.png"
              class="shrink-announce-image"
            >
          </div>
          <div class="col col-sm-9">
            <p>We are working with Cirrus Aircraft to form partnerships for like-minded pilots at YOUR local airport.  Set a listing alert at your favorites airports to be notified when an SR22, SR20 or Vision Jet partnership is being formed and own the best selling GA aircraft for a fraction of buying it alone!  Co-Ownership with great pilots...it just makes sense!</p>

            <router-link
              class="btn blue"
              :to=" { name: 'cirrus' }"
            >
              Learn More
            </router-link>
          </div>
        </div>
      </div>
    </section> -->

    <section id="blog">
      <div class="container">
        <h2>
          ShareMyAircraft Blog
        </h2>

        <div class="row previews">
          <blog-post-preview
            v-for="postPreview of wpHpPosts"
            :key="postPreview.id"
            :value="postPreview"
            :wide="true"
            class="preview col"
          />
        </div>
      </div>
    </section>

    <!-- Nearest Shares -->
    <section id="nearest-shares">
      <div class="container">
        <div class="header">
          <h2>
            Nearest Planes
          </h2>
          <div class="subheading">
            The planes closest to {{ geoAddress }}
          </div>
        </div>
        <div class="row shares">
          <div
            v-for="share in nearestShares"
            :key="share.id"
            class="col col-sm-12 col-md-6"
          >
            <sma-share-card
              class="share"
              :share="share"
            />
          </div>
        </div>
        <a
          class="btn blue"
          @click="onShowMoreNearestBtnClick"
        >
          See More
        </a>
      </div>
    </section>

    <!-- Ad 0 -->
    <!-- <section class="ad">
      <div class="container">
        <a :href="ads[0].href" target="_blank">
          <img :src="ads[0].img">
        </a>
      </div>
    </section> -->

    <!-- List Sections -->
    <section
      v-for="(section, i) of sections"
      :key="i"
      class="list-section"
      :class="{ reverse: i % 2 === 1 }"
    >
      <div class="container">
        <div class="section-container row">
          <div class="img-container col col-sm-12">
            <!-- <img :src="section.img"> -->
            <laptop-img :src="section.img" />
          </div>

          <div class="col col-sm-12">
            <h3>{{ section.header }}</h3>
            <div class="body">
              {{ section.body }}
            </div>
            <ul>
              <li
                v-for="(li, j) of section.ul"
                :key="j"
              >
                <div class="bullet">
                  <fa-icon :icon="li.bullet" />
                </div>
                <div>
                  <h4>{{ li.header }}</h4>
                  <div class="body">
                    {{ li.body }}
                  </div>
                </div>
              </li>
            </ul>

            <div class="checks">
              <div
                v-for="(check, j) of section.checks"
                :key="j"
                class="check"
              >
                <fa-icon
                  class="icon"
                  icon="check"
                />
                {{ check }}
              </div>
            </div>
          </div>
        </div>
        <a
          class="btn blue"
          @click="section.btn.onClick"
        >
          {{ section.btn.text }}
        </a>
      </div>
    </section>

    <!-- Ad 1 -->
    <section class="ad">
      <div class="container">
        <a
          :href="ads[1].href"
          target="_blank"
        >
          <img :src="ads[1].img">
        </a>
      </div>
    </section>

    <!-- New Features -->
    <section id="new-features">
      <div class="container">
        <h2>
          RECEIVE EMAIL ALERTS FOR NEW LISTINGS AT YOUR FAVORITE AIRPORT
        </h2>
        <div class="row features">
          <div
            v-for="(feature,i) in newFeatures"
            :key="i"
            class="col col-sm-8 feature"
          >
            <div class="img-container">
              <img
                :src="feature.img"
                :style="feature.imgCss"
              >
            </div>
            <h3>
              {{ feature.header }}
            </h3>
            <div class="body">
              {{ feature.body }}
            </div>
            <div class="link">
              <router-link :to="feature.to">
                Learn More
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Pricing -->
    <!--
    <section id="pricing">
      <div class="container">
        <h2>Simple, transparent pricing.</h2>
        <div class="subheading">
          Always know what you`ll Pay
        </div>
        <div class="table-container">
          <table>
            <thead>
              <tr>
                <th />
                <th
                  v-for="(col, i) of priceTable.columns"
                  :key="i"
                  class="header"
                >
                  {{ col.header }}
                </th>
              </tr>
              <tr>
                <th />
                <th
                  v-for="(col, i) of priceTable.columns"
                  :key="i"
                  class="body"
                >
                  {{ col.body }}
                </th>
              </tr>
              <tr>
                <th />
                <th
                  v-for="(col, i) of priceTable.columns"
                  :key="i"
                  class="price"
                >
                  {{ col.price }}
                </th>
              </tr>
              <tr>
                <th />
                <th
                  v-for="(col, i) of priceTable.columns"
                  :key="i"
                  class="sub"
                >
                  {{ col.sub }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(row, i) of priceTable.rows"
                :key="i"
              >
                <th scope="row">
                  {{ row.label }}
                  <button
                    v-if="row.tooltip"
                    class="tooltip-btn"
                  >
                    <fa-icon icon="info" />
                    <div class="tooltip">
                      {{ row.tooltip }}
                    </div>
                  </button>
                </th>
                <td
                  v-for="(cell, j) of row.data"
                  :key="j"
                >
                  <fa-icon
                    v-if="cell === true"
                    icon="check"
                    class="check"
                  />
                  <span v-else-if="cell">{{ cell }}</span>
                </td>
              </tr>
              <tr>
                <td />
                <td
                  v-for="(col, i) of priceTable.columns"
                  :key="i"
                >
                  <a
                    class="btn blue"
                    @click="col.btn.onClick"
                  >
                    {{ col.btn.text }}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="mobile-table-container">
          <table>
            <thead>
              <tr>
                <th />
                <th
                  class="header"
                >
                  {{ priceTable.columns[0].header }}
                </th>
              </tr>
              <tr>
                <th />
                <th
                  class="body"
                >
                  {{ priceTable.columns[0].body }}
                </th>
              </tr>
              <tr>
                <th />
                <th
                  class="price"
                >
                  {{ priceTable.columns[0].price }}
                </th>
              </tr>
              <tr>
                <th />
                <th
                  class="sub"
                >
                  {{ priceTable.columns[0].sub }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(row, i) of priceTable.rows"
                :key="i"
              >
                <th scope="row">
                  {{ row.label }}
                  <button
                    v-if="row.tooltip"
                    class="tooltip-btn"
                  >
                    <fa-icon icon="info" />
                    <div class="tooltip">
                      {{ row.tooltip }}
                    </div>
                  </button>
                </th>
                <td
                  v-for="(cell, j) of row.data.slice(0,-1)"
                  :key="j"
                >
                  <fa-icon
                    v-if="cell === true"
                    icon="check"
                    class="check"
                  />
                  <span v-else-if="cell">{{ cell }}</span>
                </td>
              </tr>
              <tr>
                <td />
                <td
                  v-for="(col, i) of priceTable.columns.slice(0,-1)"
                  :key="i"
                >
                  <a
                    class="btn blue"
                    @click="col.btn.onClick"
                  >
                    {{ col.btn.text }}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <thead>
              <tr>
                <th />
                <th
                  class="header"
                >
                  {{ priceTable.columns[1].header }}
                </th>
              </tr>
              <tr>
                <th />
                <th
                  class="body"
                >
                  {{ priceTable.columns[1].body }}
                </th>
              </tr>
              <tr>
                <th />
                <th
                  class="price"
                >
                  {{ priceTable.columns[1].price }}
                </th>
              </tr>
              <tr>
                <th />
                <th
                  class="sub"
                >
                  {{ priceTable.columns[1].sub }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(row, i) of priceTable.rows"
                :key="i"
              >
                <th scope="row">
                  {{ row.label }}
                  <button
                    v-if="row.tooltip"
                    class="tooltip-btn"
                  >
                    <fa-icon icon="info" />
                    <div class="tooltip">
                      {{ row.tooltip }}
                    </div>
                  </button>
                </th>
                <td>
                  <fa-icon
                    icon="check"
                    class="check"
                  />
                </td>
              </tr>
              <tr>
                <td />
                <td>
                  <a
                    class="btn blue"
                    @click="priceTable.columns[1].btn.onClick"
                  >
                    {{ priceTable.columns[1].btn.text }}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    -->

    <!-- Ad 2 -->
    <!-- <section class="ad">
      <div class="container">
        <a :href="ads[2].href" target="_blank">
          <img :src="ads[2].img">
        </a>
      </div>
    </section> -->

    <!-- Testimonials -->
    <section id="testimonials">
      <div class="container">
        <h2>
          You're in great company.
        </h2>
        <div class="subheading">
          See what your clients say about us
        </div>
        <div class="row testimonials">
          <div
            v-for="(testimonial,i) in testimonials"
            :key="i"
            class="col col-sm-8 testimonial"
          >
            <div class="rating">
              <div class="stars">
                <fa-icon
                  v-for="(star, j) of testimonial.stars"
                  :key="j"
                  :icon="star"
                  class="star"
                />
              </div>
              <div class="numeric">
                {{ testimonial.stars.reduce((total, star) => total + (star === 'star' ? 1 : 0.5), 0) }} / 5
              </div>
            </div>
            <div class="body">
              {{ testimonial.body }}
            </div>
            <div class="author">
              {{ testimonial.author }}
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Ad 3 -->
    <section class="ad">
      <div class="container">
        <a
          :href="ads[3].href"
          target="_blank"
        >
          <img :src="ads[3].img">
        </a>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable global-require */

import { xor } from 'lodash';
import { mapGetters } from 'vuex';

import SmaShareCard from '@/components/ShareCard';
import {
  FETCH_COUNTS,
  FETCH_GEO_LOCATION,
  FETCH_RECENT_SHARES,
  SEARCH,
  FETCH_NEAREST_SHARES,
  SHOW_MODAL,
  WP_FETCH_HP_POSTS
} from '@/store/action-types';
import LaptopImg from '@/components/LaptopImg.vue';
import createSearchIcon from '@/utils/create-search-icon';
import BlogPostPreview from '@/components/BlogPostPreview';

const hasAllValues = (arr, opts) => xor(arr, opts.map(opt => opt.value)).length === 0;

export default {
  name: 'SmaIndex',

  components: {
    SmaShareCard,
    LaptopImg,
    BlogPostPreview
  },

  data() {
    return {
      hero: {
        bg: 'linear-gradient(45deg, rgb(1, 34, 64) 0%, white 100%)',
        // img: require('@/assets/home/hero.png'),
        videoId: 'd-ynsR2dvm4',
        header: 'Connect with the GA Community & <strong>Change the Math!</strong>',
        body: 'Find what you\'re looking for: Planes, Pilots & Professionals'
      },
      callouts: [
        {
          icon: 'plane',
          header: 'PLANES',
          body: 'Let us help you reduce the expense of ownership! With the SMA Network you can more easily find partners or renters that fit your comfort level and help you reduce your ownership or operating costs, or both.'
        },
        {
          icon: 'user-friends',
          header: 'PILOTS',
          body: 'Let the SMA Network help you find the perfect private rental, ownership opportunity, or potential partners at your local airport to co-own a new or used aircraft. The SMA Network is designed to help you Fly More.'
        },
        {
          icon: 'chalkboard-teacher',
          header: 'PROS',
          body: 'For Commercial Pilots and Instructors, the SMA Network is designed to help provide you more income opportunities in the air (or on the ground) and be found by more owners and pilots in your area so you can  Fly More.'
        }
      ],
      sponsors: [
        {
          img: require('@/assets/home/coflyt.png'),
          href: 'https://www.sharemyaircraft.com/coflyt'
        },
        {
          img: require('@/assets/home/avemco.png'),
          href: 'https://www.avemco.com/'
        },
        {
          img: require('@/assets/home/cirrus.png'),
          href: 'https://cirrusaircraft.com/'
        },
        {
          img: require('@/assets/home/aopa.png'),
          href: 'https://aopa.org/'
        },
        {
          img: require('@/assets/home/eaa.png'),
          href: 'https://www.eaa.org/eaa'
        },
        {
          img: require('@/assets/home/aviation.png'),
          href: 'https://www.aviationconsumer.com/'
        },
        {
          img: require('@/assets/home/avweb.png'),
          href: 'https://www.avweb.com/'
        }
      ],
      ads: [
        {
          img: require('@/assets/home/ad-garmin.png'),
          href: null
        },
        {
          img: require('@/assets/home/ad-coflyt.png'),
          href: '/coflyt'
        },
        {
          img: require('@/assets/home/ad-thegreendot.png'),
          href: null
        },
        {
          img: require('@/assets/home/ad-eaa.png'),
          href: 'https://www.eaa.org'
        }
      ],
      sections: [
        {
          img: require('@/assets/home/section0.png'),
          header: 'REDUCE THE EXPENSE OF OWNERSHIP',
          body: 'Whether it’s your Fixed or Operating Expenses, see how SMA can help make flying your plane more affordable',
          ul: [
            {
              bullet: 'user-friends',
              header: 'RENT YOUR PLANE TO AREA PILOTS OF YOUR CHOICE!',
              body: 'With the SMA Network you can find the perfect pilot (up to 4 without changing your insurance) to rent your plane hourly. SMA Strategies can walk you through best practice on agreements, scheduling and even handling the money so your rental partnership will work seamlessly.'
            },
            {
              bullet: 'percent',
              header: 'SELL A FRACTION OF YOUR PLANE TO AREA PILOTS OF YOUR CHOICE (CO-OWNERSHIP)',
              body: 'Successful Partnerships are a function of finding the right partners and setting expectations on the front-end. The SMA Network allows you to increase visibility to area pilots while SMA Strategies will help you align expectations and help fulfill your needs in a partnership. From co-ownership agreements, to scheduling and maintenance to even handling the money - let us help.'
            },
            {
              bullet: 'users',
              header: 'LEASE YOUR PLANE TO A PROFESSIONAL ORGANIZATION OR FLIGHT INSTRUCTOR',
              body: 'Both flight schools and instructors are in need of primary and advanced training planes. With the SMA Network, your plane can earn you income while they fly and keep it maintained.'
            }
          ],
          checks: [
            'More Options',
            'More Flight Hours',
            'Reduce Expenses'
          ],
          btn: {
            text: 'LIST YOUR PLANE ONLY $12/MONTH',
            onClick: () => {
              if (!this.me) {
                this.$store.dispatch(SHOW_MODAL, {
                  name: 'signup',
                  afterSignupNav: { name: 'post' }
                });
              } else {
                this.$router.push({ name: 'post' });
              }
            }
          }
        },
        {
          img: require('@/assets/home/section1.jpg'),
          header: 'THE SMA NETWORK HELPS PILOTS BECOME OWNERS',
          body: 'The SMA Network can help you find a great plane and pair you up with other pilots that compliment your flying goals in your area!',
          ul: [
            {
              bullet: 'dollar-sign',
              header: 'RENT (SHARE) A PLANE FROM AN OWNER AT YOUR LOCAL AIRPORT!',
              body: 'The SMA Network can help you find your ideal aircraft(s), connecting you with an owner who is looking for great pilots to help their aircraft fly more.'
            },
            {
              bullet: 'percent',
              header: 'OWNERSHIP IS IN REACH, AT A FRACTION OF THE PRICE',
              body: 'The SMA Network can help you find the perfect fractional situation where you can enjoy all the benefits of true plane ownership, but without the full costs associated with owning and maintaining a plane alone!'
            },
            {
              bullet: 'people-carry',
              header: 'FIND WHAT YOU ARE LOOKING FOR',
              body: 'If the SMA Network doesn’t have the plane you are looking for, we can help you form your own partnership to CO-OWN a new or used plane. We can then help with all the sharing docs, financing, insurance, and training. And after, we can help with scheduling, maintenance and even handle the money! Making the perfect partnership work perfectly.'
            }
          ],
          checks: [
            'Rent a Plane',
            'Buy a Fraction',
            'or Form a Partnership'
          ],
          btn: {
            text: 'SEARCH FOR PLANES',
            onClick: () => {
              if (!this.me) {
                this.$store.dispatch(SHOW_MODAL, {
                  name: 'signup',
                  afterSignupNav: { name: 'find' }
                });
              } else {
                this.$router.push({ name: 'find' });
              }
            }
          }
        },
        {
          img: require('@/assets/home/section2.png'),
          header: 'EARN MORE AS A COMMERCIAL PILOT OR FLIGHT INSTRUCTOR WITH THE SMA NETWORK',
          body: 'SMA is creating The Destination to help Professional Pilots get more exposure and opportunities to fly and earn more.',
          ul: [
            {
              bullet: 'archive',
              header: 'PROVIDE NEEDED INSTRUCTION TO AREA PILOTS',
              body: 'The SMA Network makes it easier for pilots in need to find you for their Transition, Recurrent or Primary Training.'
            },
            {
              bullet: 'info-circle',
              header: 'COMMERCIAL PILOTS WANTED',
              body: 'Imagine flying one of the most advance GA aircrafts for a private partnership, think Diamond D42/62 or the Cirrus SR22 - The SMA Network provides exposure to Commercial Pilots to fly and earn more!'
            },
            {
              bullet: ['far', 'square'],
              header: 'GET INTO THE AIRCRAFT MANAGEMENT BUSINESS',
              body: 'Managing Aircraft for owners is not a new idea but SMA is making it easier to start (or expand) your Management business. Get paid and be a trusted source to help manage aircraft for owners. SMA can walk you through how to start, run and grow your business.'
            }
          ],
          checks: [
            'Earn More',
            'Fly More',
            'Grow More'
          ],
          btn: {
            text: 'Sign Up for Free',
            onClick: () => {
              if (!this.me) {
                this.$store.dispatch(SHOW_MODAL, {
                  name: 'signup',
                  onComplete: () => {
                    setTimeout(() => {
                      if (!this.me.isInstructor) {
                        this.$store.dispatch(SHOW_MODAL, { name: 'instructor-signup' });
                      }
                    }, 0);
                  }
                });
              } else if (!this.me.isInstructor) {
                this.$store.dispatch(SHOW_MODAL, { name: 'instructor-signup' });
              } else {
                this.$router.push({ name: 'manage-instructor' });
              }
            }
          }
        }
      ],
      priceTable: {
        columns: [
          {
            header: 'View & List Aircraft Shares',
            body: 'Access our community of Aviation Enthusiasts and Aspiring Pilots.',
            price: '$0',
            sub: null,
            btn: {
              text: 'Sign Up For Free',
              onClick: () => {
                if (!this.me) {
                  this.$store.dispatch(SHOW_MODAL, {
                    name: 'signup',
                    afterSignupNav: { name: 'post' }
                  });
                } else {
                  this.$router.push({ name: 'post' });
                }
              }
            }
          },
          {
            header: 'SMA Managed Aircraft Starter Kit',
            body: 'We will help you through the process of setting up your rental, Co-Ownership or Owner-financed partnership!',
            price: '$299/$499',
            sub: 'One-time',
            btn: {
              text: 'Learn More',
              onClick: () => {
                this.$router.push({ name: 'sharing' });
              }
            }
          }
        ],
        rows: [
          {
            label: 'Advertising and Access to Pilot Network',
            tooltip: null,
            data: [
              'Free',
              true
            ]
          },
          {
            label: 'Aircraft listing and Share Liquidity',
            tooltip: 'Aircraft Sales Advertising and marketing to assist with aircraft share liquidity to transfer ownership to new owners for a 1% filing and transaction fee in addition a monthly membership plan.',
            data: [
              '',
              true
            ]
          },
          {
            label: 'Co-Ownership Support, Legal Docs, help with insurance, financing etc',
            tooltip: 'Our team will assist you with filing initial Legal, Insurance, and Financing applications for an inclusive fee in addition to our yearly membership plan.',
            data: [
              '',
              true
            ]
          }
        ]
      },
      testimonials: [
        {
          stars: ['star', 'star', 'star', 'star', 'star'],
          body: 'I was only looking to cover expenses, but I was surprised at the amount I got per hour for my Cirrus. It made the difference in my flying!',
          author: 'Stratis H, KMVY'
        },
        {
          stars: ['star', 'star', 'star', 'star', 'star-half'],
          body: 'I really liked the fact that I could ask for exactly who I wanted. My plane is complex, so it was important for me to have someone with minimum time in my type and an instrument rating. It was easy to request this. Thank you.',
          author: 'James M, KSDL'
        },
        {
          stars: ['star', 'star', 'star', 'star', 'star'],
          body: 'It was so easy to use - I loved making and posting a flyer. Also, my friends who flew my plane, posted comments which helped a great deal as people viewing my listing got a sense of my plane (and me) before meeting me. ',
          author: 'Neil B, KHPN'
        }
      ],
      newFeatures: [
        {
          img: require('@/assets/phonetext.png'),
          imgCss: { 'height': '3.2rem', 'margin-bottom': '.8rem' },
          header: 'New Listing Alerts',
          body: 'Get email alerts when a new share has been posted to your favorite airports!',
          to: { name: 'manage-favorite-airports' }
        }
      ],
      searchForm: {
        location: '',
        lat: 0,
        lng: 0,
        radius: 0,
        q: '',
        airport: null,
        searchTypes: ['user', 'instructor', 'share'],
        shareCategories: [
        ],
        instructorTags: [
        ],
        sortBy: 'dist',
        sortDir: 'asc'
      },
      sortByOptions: [
        { label: 'Closest', value: 'dist asc' },
        { label: 'Price (highest)', value: 'ratePerHour desc' },
        { label: 'Price (lowest)', value: 'ratePerHour asc' },
        { label: 'List Date (newest)', value: 'listDate desc' },
        { label: 'List Date (oldest)', value: 'listDate asc' }
      ],
      shareCategoryOptions: [
        { label: 'Private Owner Rental', value: 'PRIVATE_OWNER_RENTAL' },
        { label: 'Private Owner Share Purchase', value: 'PRIVATE_OWNER_SHARE_PURCHASE' },
        { label: 'Partnership to Purchase New or Used Aircraft', value: 'PARTNERSHIP_REQUEST_TO_PURCHASE' },
        { label: 'Flight School Rental', value: 'FBO_RENTAL' },
        { label: 'Flying Club', value: 'FLIGHT_CLUB' }
      ],
      instructorTagOptions: [
        { label: 'Private', value: 'Private' },
        { label: 'Instrument', value: 'Instrument' },
        { label: 'Tail Wheel', value: 'Tail Wheel' },
        { label: 'Acrobatic', value: 'Acrobatic' },
        { label: 'Sea Plane', value: 'Sea Plane' },
        { label: 'Multi-Engine', value: 'Multi-Engine' },
        { label: 'High Performance', value: 'High Performance' },
        { label: 'Turbo', value: 'Turbo' },
        { label: 'Jet', value: 'Jet' },
        { label: 'Cirrus CSIP / Transition', value: 'Cirrus CSIP / Transition' },
        { label: 'Helicopter / Rotorcraft', value: 'Helicopter / Rotorcraft' }
      ],
      mapCenter: { lat: 37, lng: -98.5 },
      mapZoom: 7,
      searchBoxVisible: true,
      shareCategoryIsVisible: false,
      instructorTagIsVisible: false,
      markerIconUrl: require('@/assets/map-marker-airport.svg'),
      clusterIconUrl: require('@/assets/map-marker-airport-cluster.svg'),
      legendIconUrl: require('@/assets/map-marker-legend.svg'),
      recentPage: 1,
      nearestPage: 1
    };
  },

  computed: {
    ...mapGetters([
      'me',
      'geoLatLng',
      'geoAddress',
      'recentShares',
      'nearestShares',
      'totalCounts',
      'serachAirportCounts',
      'wpHpPosts'
    ])
  },

  async mounted() {
    this.searchForm.shareCategories = this.shareCategoryOptions.map(opt => opt.value);
    this.searchForm.instructorTags = this.instructorTagOptions.map(opt => opt.value);
    this.searchForm.searchTypes = ['user', 'instructor', 'share'];

    if (window.innerWidth <= 768) {
      this.searchBoxVisible = false;
      this.mapZoom = 6;
      this.mapCenter = { lat: 40, lng: -98.5 };
    }

    await this.$store.dispatch(FETCH_GEO_LOCATION);

    await Promise.all([
      this.$store.dispatch(FETCH_COUNTS),
      this.$store.dispatch(FETCH_RECENT_SHARES, {
        address: this.geoAddress,
        limit: 12
      }),
      this.$store.dispatch(FETCH_NEAREST_SHARES, {
        address: this.geoAddress,
        lat: this.geoLatLng.lat,
        lng: this.geoLatLng.lng,
        limit: 12
      }),
      this.$store.dispatch(SEARCH, {
        lat: this.geoLatLng.lat,
        lng: this.geoLatLng.lng,
        radius: 500000,
        limit: 1
      }),
      this.$store.dispatch(WP_FETCH_HP_POSTS)
    ]);

    this.searchForm.location = this.geoAddress;
    this.searchForm.lat = this.geoLatLng.lat;
    this.searchForm.lng = this.geoLatLng.lng;
    this.mapCenter = { lat: this.searchForm.lat, lng: this.searchForm.lng };
  },

  methods: {
    createSearchIcon,

    signup() {
      if (!this.me) {
        this.$store.dispatch(SHOW_MODAL, {
          name: 'signup'
        });
      } else {
        this.$router.push({ name: 'manage-account' });
      }
    },

    onClusterClick(cluster) {
      const center = cluster.getCenter();

      if (!this.me) {
        this.$store.dispatch(SHOW_MODAL, {
          name: 'signup',
          afterSignupNav: {
            name: 'find',
            query: {
              lat: center.lat(),
              lng: center.lng()
            }
          }
        });

        return;
      }

      this.$router.push({
        name: 'find',
        query: {
          lat: center.lat(),
          lng: center.lng()
        }
      });
    },

    onMapMarkerClick(count) {
      if (!this.me) {
        this.$store.dispatch(SHOW_MODAL, {
          name: 'signup',
          afterSignupNav: {
            name: 'find',
            query: {
              lat: count.lat,
              lng: count.long
            }
          }
        });

        return;
      }

      this.$router.push({
        name: 'find',
        query: {
          lat: count.lat,
          lng: count.long
        }
      });
    },

    onAutocompletePlaceChanged(place) {
      this.form.location = place.formatted_address;
      this.form.lat = place.geometry.location.lat();
      this.form.lng = place.geometry.location.lng();
    },

    onSearchBtnClicked() {
      this.$store.dispatch(SHOW_MODAL, {
        name: 'signup',
        afterSignupNav: {
          name: 'find',
          query: {
            lat: this.searchForm.lat,
            lng: this.searchForm.lng,
            q: this.searchForm.q || undefined,
            location: this.searchForm.location || undefined,
            airport: this.searchForm.airport || undefined,
            shareCategories: hasAllValues(this.searchForm.shareCategories, this.shareCategoryOptions)
              ? undefined
              : this.searchForm.shareCategories,
            instructorTags: hasAllValues(this.searchForm.instructorTags, this.instructorTagOptions)
              ? undefined
              : this.searchForm.instructorTags,
            searchTypes: this.searchForm.searchTypes.length < 3 ? this.searchForm.searchTypes : undefined
          }
        }
      });
    },

    onCategoriesBtnClicked() {
      this.shareCategoryIsVisible = !this.shareCategoryIsVisible;
    },

    onCategoriesClickOutside() {
      if (this.shareCategoryIsVisible) {
        this.shareCategoryIsVisible = false;
      }
    },

    onTagsBtnClicked() {
      this.instructorTagIsVisible = !this.instructorTagIsVisible;
    },

    onTagsClickOutside() {
      if (this.instructorTagIsVisible) {
        this.instructorTagIsVisible = false;
      }
    },

    closeBtnClicked() {
      this.searchBoxVisible = false;
    },

    onShowSearchBoxBtnClicked() {
      this.searchBoxVisible = true;
    },

    onShowMoreNearestBtnClick(e) {
      e.preventDefault();

      if (!this.me) {
        this.$store.dispatch(SHOW_MODAL, {
          name: 'signup'
        });
        return;
      }

      this.$store.dispatch(FETCH_NEAREST_SHARES, {
        address: this.geoAddress,
        lat: this.geoLatLng.lat,
        lng: this.geoLatLng.lng,
        limit: 8,
        page: ++this.nearestPage
      });
    },

    onShowMoreRecentBtnClick(e) {
      e.preventDefault();

      if (!this.me) {
        this.$store.dispatch(SHOW_MODAL, {
          name: 'signup'
        });
        return;
      }

      this.$store.dispatch(FETCH_RECENT_SHARES, {
        address: this.geoAddress,
        limit: 8,
        page: ++this.recentPage
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.home-page {
  margin-bottom: 24px;
  color: $sma-dark-gray;
}

section {
  padding: 48px 0;
  border-bottom: 1px solid $sma-blue;

  &:last-child {
    border-bottom: 0;
  }
}

h1,
h2,
h3,
h4 {
  color: inherit;
  font-weight: inherit;
  margin: 0;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: $breakpoint-lg) {
    max-width: $grid-max-width-md !important;
  }
}

#hero {
  position: relative;
  padding: 0;
  border: 0;

  .container {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .text {
    color: $sma-near-white;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @media (min-width: $breakpoint-md) {
      padding-right: 16px;
      text-align: left;
    }

    h2::v-deep {
      font-size: 50px;
      font-weight: 400;

      strong {
        color: #ffffff;
        font-weight: 700;
      }
    }

    .body {
      margin-top: 16px;
      font-size: 20px;
      font-weight: 200;
    }
  }

  .img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 48px;

    @media (min-width: $breakpoint-md) {
      padding-left: 16px;
      margin-top: 0;
    }

    img {
      width: 100%;
    }
  }

  .video {
    margin-top: 32px;

    @media (min-width: $breakpoint-md) {
      margin-top: 0;
    }
  }

  .video-container {
    position: relative;
    padding-top: calc((9 / 16) * 100%);
    width: 100%;

    > iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .waves {
    position: relative;
    bottom: -8px;
  }
}

#callouts {
  border-bottom: 1px solid $sma-blue;
  padding-bottom: 32px;

  > .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.callout-container {
  margin-bottom: 24px;
}

.callout {
  &:not(:last-child) {
    padding-right: 40px;
  }

  .icon {
    display: flex;
    justify-content: center;
    font-size: 64px;
    margin-bottom: 24px;
  }

  .header {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 12px;
  }

  .body {
    font-size: 16px;
    text-align: center;
    line-height: 28px;
  }
}

#map {
  padding-top: 32px;
  padding-bottom: 32px;

  .sponsors {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    @media (min-width: $breakpoint-md) {
      flex-wrap: nowrap;
      justify-content: space-between;
    }
  }

  .sponsor {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33%;

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    @media (min-width: $breakpoint-md) {
      .sponsor {
        width: auto;
        margin-bottom: 0;
      }
    }
  }

  h2 {
    text-align: center;
    font-size: 52px;
    font-weight: 500;
    margin-top: 48px;

    @media (min-width: $breakpoint-md) {
      width: 80%;
    }
  }

  .sub-h2 {
    text-align: center;
    font-size: 26px;
    line-height: 1;
    font-weight: 500;
    margin-top: 24px;
    margin-bottom: 0;

    @media (min-width: $breakpoint-md) {
      width: 80%;
    }
  }

  .map-container {
    margin-top: 48px;
    position: relative;
    width: 100%;
  }

  .map {
    position: relative;
    padding-bottom: calc(100% * 11 / 16);
  }

  .legend {
    margin-top: 8px;

    @media (min-width: $breakpoint-md) {
      margin-top: 0;
      flex-direction: column;
      position: absolute;
      bottom: 32px;
      left: 16px;
      padding: 16px;
      background-color: $sma-white;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    }

    .header {
      font-weight: 700;
    }

    .content {
      display: flex;
      margin-top: 8px;

      @media (min-width: $breakpoint-md) {
        flex-direction: column;
      }

      .type {
        display: flex;
        align-items: center;

        &:not(:last-of-type) {
          margin-bottom: 0;
          margin-right: 8px;

          @media (min-width: $breakpoint-md) {
            margin-bottom: 8px;
            margin-right: 0;
          }
        }

        img {
          height: 32px;
          width: 32px;
          margin-right: 8px;
        }
      }
    }
  }

  h3 {
    margin-top: 48px;
    font-size: 38px;
    font-weight: 700;
  }

  .stats {
    margin-top: 48px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 48px;

    .num {
      color: $sma-black;
      font-weight: 900;
    }

    @media (min-width: $breakpoint-md) {
      width: 65%;
      font-size: 32px;
    }
  }

  & > .btn {
    margin-top: 48px;
  }

  .col {
    position: relative;
    height: 100%;
    background-color: $sma-near-white;
  }

  .form {
    position: absolute;
    top: 1rem;
    left: 1.5rem;
    right: 1.5rem;
    display: flex;
    background-color: $sma-white;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    padding: 0.5rem 1rem;
    font-size: 0.875rem;

    @media only screen and (max-width: $breakpoint-lg) {
      flex-wrap: wrap;
    }

    .col {
      background: transparent;
    }
  }

  .close-btn {
    cursor: pointer;
    position: absolute;
    top: 0.1em;
    right: 0.1em;
    color: $sma-gray;
    font-size: 1.5em;
    border: 0;
    background-color: transparent;
  }

  .view-all-btn {
    position: absolute;
    bottom: 1rem;
    left: calc(50% - 100px);
    width: 200px;

    @media only screen and (max-width: $breakpoint-sm) {
      top: 13.5em;
      left: 3em;
    }
  }

  .home-btn {
    position: absolute;
    bottom: 1rem;
    left: 0.5rem;
  }

  .zoom-btns {
    position: absolute;
    bottom: 1rem;
    right: 0.5rem;
    display: flex;
    flex-direction: column;
  }

  .show-search-btn {
    position: absolute;
    top: 0;
    left: 0.5rem;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
  }

  .label {
    color: $sma-gray;
    margin-bottom: 0.5rem;
    margin-left: 0.25rem;
  }

  .input {
    border: 1px solid $sma-light-gray;
    border-radius: 0.25rem;
    padding: 0.5rem;
    width: 100%;

    @media only screen and (max-width: $breakpoint-sm) {
      margin: 0.5rem 0;
    }

    &.address {
      width: 100%;
      background: #fff;
      border-radius: 10em;
      font-size: 1.5em;
      padding: 0.2em 0.5em;
    }

    &.model {
      width: 8rem;

      @media only screen and (max-width: $breakpoint-lg) {
        width: 100%;
      }
    }
  }

  .amount {
    text-align: center;
  }

  .results {
    flex: 1;
    padding: 1rem;
    height: 100%;
    overflow: auto;
  }

  .stats {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

    @media only screen and (max-width: $breakpoint-sm) {
      flex-direction: column;

      div:first-child {
        margin-bottom: 1rem;
      }
    }
  }

  .dropdown {
    display: flex;
    align-items: center;
    color: $sma-gray;
    font-size: 0.875rem;
    border: 1px solid $sma-gray;
    border-radius: 0.25rem;
    padding: 0.5rem 1rem;

    .text {
      margin-right: 1rem;
    }
  }

  .share {
    margin: 1rem 0;
  }

  .no-shares {
    text-align: center;
  }

  .col-flex {
    @media only screen and (max-width: $breakpoint-lg) {
      height: auto;
    }
  }

  .categories-btn {
    position: relative;

    .categories {
      position: absolute;
      background-color: $sma-white;
      top: 100%;
      padding: 1rem;
      z-index: 999;

      .drop {
        display: flex;
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 0;
        }

        & input {
          width: auto;
          margin: 0 4px 0 -4px;
        }
      }
    }
  }

  .search-types {
    @media (min-width: $breakpoint-lg) {
      height: 58px;
    }

    & > .col {
      align-items: center;
      height: auto;

      @media (min-width: $breakpoint-lg) {
        align-items: start;
        margin: 0;

        /* margin-top: 4px; */
      }

      & > * {
        width: fit-content;
      }
    }
  }
}

.ad {
  img {
    width: 100%;
  }
}

#blog {
  h2 {
    font-size: 38px;
    font-weight: 700;
  }

  .previews {
    margin-top: 2rem;

    > * + * {
      margin-top: 2rem;
    }
  }
}

.list-section {
  .img-container {
    display: flex;
    align-items: center;
    margin-bottom: 32px;

    @media (min-width: $breakpoint-md) {
      margin-bottom: 0;
      padding-right: 48px;
    }

    > img {
      width: 100%;
    }
  }

  .checks {
    display: flex;
    flex-direction: column;

    @media (min-width: $breakpoint-md) {
      flex-direction: inherit;
    }
  }

  h3 {
    font-size: 38px;
    font-weight: 700;
  }

  .body {
    font-size: 18px;
  }

  ul {
    padding: 0;
    list-style-type: none;
    margin-top: 32px;
  }

  li {
    display: flex;

    &:not(:first-child) {
      margin-top: 32px;
    }

    h4 {
      font-size: 18px;
      font-weight: 500;
    }

    .body {
      font-size: 16px;
    }
  }

  .bullet {
    font-size: 18px;
    background-color: $sma-light-gray;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2em;
    height: 2em;
    border-radius: 99999px;
    flex: none;
    margin-right: 8px;
  }

  .check {
    &:not(:first-child) {
      margin-left: 0;

      @media (min-width: $breakpoint-md) {
        margin-left: 16px;
      }
    }

    .icon {
      color: #22bc66;
    }
  }

  .btn {
    margin-top: 32px;
  }

  &.reverse {
    .row {
      flex-direction: row-reverse;
    }

    .img-container {
      @media (min-width: $breakpoint-md) {
        padding-left: 48px;
      }
    }
  }
}

#recent-shares,
#nearest-shares {
  .header {
    text-align: center;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 600;
  }

  .subheading {
    margin-top: 8px;
  }

  .shares {
    margin-top: 24px;
  }
}

#announcements {
  h2 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 24px;
  }

  img { width: 100%; }
}

#new-features {
  h2 {
    font-size: 38px;
    font-weight: 700;
  }

  .features {
    justify-content: center;
    margin-top: 48px;
  }

  .feature {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    h3 {
      margin-top: 16px;
      color: $sma-blue;
      font-size: 18px;
      font-weight: 500;
    }

    .body {
      margin-top: 1rem;
      text-align: center;
    }

    .link {
      margin-top: 16px;
      color: $sma-blue;
      font-weight: 500;
    }
  }
}

#pricing {
  h2 {
    font-size: 38px;
    font-weight: 700;
  }

  .subheading {
    margin-top: 8px;
    font-size: 20px;
    font-weight: 400;
  }

  .table-container {
    @media (max-width: $breakpoint-md) {
      width: 100%;
      overflow-x: auto;
      display: none;
    }
  }

  .mobile-table-container {
    display: none;

    @media (max-width: $breakpoint-md) {
      display: block;
      width: 100%;
    }

    table {
      width: 100%;
      table-layout: auto;
      overflow: hidden;
    }
  }

  table {
    margin-top: 48px;
    border-collapse: collapse;

    @media (max-width: $breakpoint-md) {
      width: 600px;
      table-layout: fixed;
    }

    thead {
      tr:last-child th {
        padding-bottom: 16px;
      }

      th {
        font-weight: 400;
        padding-bottom: 8px;
      }

      .header {
        font-size: 16px;
        font-weight: 500;
      }

      .body {
        font-size: 16px;
      }

      .price {
        font-size: 28px;
      }

      .sub {
        font-size: 12px;
      }
    }

    tbody {
      tr {
        border-top: 1px solid $sma-light-gray;
        border-bottom: 1px solid $sma-light-gray;
      }

      tr:last-child {
        border-bottom: 0;

        th,
        td {
          padding: 20px 0;
        }
      }

      th,
      td {
        font-size: 14px;
        font-weight: 200;
        padding: 12px 0;
      }

      td {
        text-align: center;
      }
    }
  }

  .tooltip-btn {
    background-color: transparent;
    border: solid 1px $sma-dark-gray;
    border-radius: 999px;
    cursor: pointer;
    width: 1.5em;
    height: 1.5em;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;

    * {
      font-size: 0.6em;
    }
  }

  $tooltip-fade-dur: 300ms;

  .tooltip-btn .tooltip {
    width: 300px;
    background-color: $sma-blue;
    color: $sma-white;
    text-align: center;
    position: absolute;
    border-radius: 5px;
    font-size: 0.8rem;
    padding: 10px;
    z-index: 1;
    top: -92%;
    left: 150%;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear $tooltip-fade-dur, opacity $tooltip-fade-dur;
  }

  .tooltip-btn:hover .tooltip {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity $tooltip-fade-dur;
  }

  .tooltip-btn .tooltip::after {
    content: " ";
    position: absolute;
    top: 1.5rem;
    right: 100%; /* To the left of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent $sma-blue transparent transparent;
  }
}

#testimonials {
  h2 {
    font-size: 38px;
    font-weight: 700;
  }

  .subheading {
    margin-top: 8px;
    font-weight: 20px;
  }

  .testimonial {
    margin-top: 48px;

    .rating {
      display: flex;
      justify-content: center;
      font-size: 16px;

      .stars {

      }

      .star {
        color: #f6ba10;
      }

      .numeric {
        margin-left: 8px;
      }
    }

    .body {
      margin-top: 16px;
      font-size: 20px;
      line-height: 32px;
      text-align: center;
    }

    .author {
      margin-top: 16px;
      font-size: 16px;
      text-align: center;
    }
  }
}
</style>
